

let json=[
  {
    type:"mpItem",
    publisher:"Arcane Vault Assembly",
    owner:"admin@arcanevaultassembly.com",


    _id: "1",
    title: "Devils Artwork Pack",
    description: "asdf",
    promotional: "All the original devil illustrations in one pack! The infernal cast of villains includes the Pit Fiend, Ice Devil, Horned Devil, Erinyes, Chain Devil, Bone Devil, Barbed Devil, Bearded Devil, Hell Hound, Imp, and Lemure.",
    picURL:"https://firebasestorage.googleapis.com/v0/b/avagms-c2fee.appspot.com/o/images%2F6001-0416DevilsPack_GMS.jpg?alt=media&token=af0fc6b4-9236-466e-a6bc-5616f2603a99",
    campaignId: "831j5041124",
    gameSystem: "5e",
    mptype:"mpImage",
    tags: "comma separated string"
},

{
  type:"mpItem",
  title: "Beasts Artwork Pack",
  description: "asdfg",   
  promotional: "Over 35 illustrations of beasts to populate your world, provide handouts, or to help illustrate wild shapes, summoned creatures, and beast encounters. Mammals, reptiles, insects, sea creatures, birds!",
  price:"$20", realizedPrice:"$14.95",
  topDisplay:true, publisher:"Arcane Vault Assembly",
  _id: "2",
  picURL:"https://firebasestorage.googleapis.com/v0/b/avagms-c2fee.appspot.com/o/images%2F4690-0416BeastPack_GMS.jpg?alt=media&token=896ec6ab-ba44-4ebc-9319-c5e39237851a",
  owner:"taylormdavidson@gmail.com",
  mptype: "mpImage",
  campaignId: "7z8d3041124"
},

{
  type:"mpItem",
  title: "Undead Artwork Pack",
  description: "asdfgh",    
  promotional: "Thirteen unique undead monsters to add horror to your next game! Undead villains including Banshee, Death Knight, Ghast, Ghost, Ghoul, Lich, Mummy, Skeleton, Specter, Vampire, Wight, Wraith, and Zombie. Enjoy!",
  price:"$49.99",
  topDisplay:true, publisher:"Arcane Vault Assembly",
  _id: "3",
  picURL:"https://firebasestorage.googleapis.com/v0/b/avagms-c2fee.appspot.com/o/images%2F1931-0416UndeadPack_GMS.jpg?alt=media&token=f6aeca25-05f4-48e8-9b83-5497fca43a31",
  isPromo: "priority1",
  mptype:"mpImage",
  gameSystem: "5e",
  campaignId: "5443h041124"
},
{
  type:"mpItem",
  title: "Elemental Artwork Pack",
  description: "asdf",    
  promotional: "Hand-crafted custom artwork for the Elementals in your RPG! Fire, Earth, Water, and Air. Includes accompanying handout for players.",
  price:"$20", publisher:"Arcane Vault Assembly",
  topDisplay:true,
  _id: "4",
  owner:"taylormdavidson@gmail.com",
  picURL: "https://firebasestorage.googleapis.com/v0/b/avagms-c2fee.appspot.com/o/images%2F354-0416ElementalPack_GMS.jpg?alt=media&token=97ef0e45-0bed-4b5f-b0ac-f75d8af77e74",
  mptype:"mpImage",
  gameSystem:"5e",
  campaignId: "2434s041124"
},
{
type:"mpItem",
title: "Weapon Artwork Pack",
description: "asdfg",    promotional: "All the weapon artwork you will ever need! 24 weapon illustrations, ready to be dropped into any game!",
price:"$20", publisher:"Arcane Vault Assembly",
topDisplay:true,
_id: "5",
picURL:"https://firebasestorage.googleapis.com/v0/b/avagms-c2fee.appspot.com/o/images%2F8101-0416WeaponPack_GMS.jpg?alt=media&token=1174a2e4-e6e6-4e22-8ad1-487f76fd3ea4",
gameSystem:"5e",
campaignId: "278zl041124",
mptype: "mpImage"
},
{
type:"mpItem",
title: "Castles Maps Pack",  publisher:"Arcane Vault Assembly",
description: "asdfgh",    promotional: "Four awesome castle battle maps to add to your library! Castle Winter, Castle Summer, The Moat House, and the hilltop Rook. Multiple floors and versions for you needs.",
price:"$20",
topDisplay:true,
_id: "6",
picURL:"https://firebasestorage.googleapis.com/v0/b/avagms-c2fee.appspot.com/o/images%2F2407-0411CastleKitAd.png?alt=media&token=968ec85c-8162-47bb-bd23-87fc5e94ee30",
gameSystem: "5e",
campaignId: "85y42041124",
mptype: "mpMap"
},
{
  type:"mpItem",
  title: "Dungeon Maps Pack",  publisher:"Arcane Vault Assembly",
  description: "asdfgh",    promotional: "A collection of 14 different dungeon, underground, and labyrinthine maps ready for use in your next adventure!",
  price:"$20",
  topDisplay:true,
  _id: "6235",
  picURL:"https://firebasestorage.googleapis.com/v0/b/avagms-c2fee.appspot.com/o/images%2F1360-0416dungeonPack_GMS.jpg?alt=media&token=a5405ea0-e8ba-41a6-b154-a40257c0d4d6",
  gameSystem: "5e",
  mptype: "mpMap",
  campaignId: "x477i041124"
  },
{
type:"mpItem",
title: "City & Towns Maps Pack",  publisher:"Arcane Vault Assembly",
description: "asdfg",    promotional: "More than 17 map images to use as maps or handouts. City streets, residences, and more, across a variety of locations.",
price:"$20",
topDisplay:true,
owner:"taylormdavidson@gmail.com",
_id: "8",
picURL:"https://firebasestorage.googleapis.com/v0/b/avagms-c2fee.appspot.com/o/images%2F1249-0416cityTownPack_GMS.jpg?alt=media&token=4317cab3-130b-48ee-b01b-78a3a3d90cee",
gameSystem: "5e",
campaignId:"286rj041124",
mptype: "mpMap"
},
{
type:"mpItem",
title: "Character Artwork Pack",  publisher:"Arcane Vault Assembly",
description: "asdfgh",    
promotional: "Wizards, rogues, monks, fighters and more! 25 character illustrations ready for use in your creations, as reference or player handouts.",
price:"$20",
topDisplay:true,
_id: "9",
picURL: "https://firebasestorage.googleapis.com/v0/b/avagms-c2fee.appspot.com/o/images%2F1094-0416CharacterPack_GMS.jpg?alt=media&token=5fc1019c-42a0-48cc-86e7-52b924eea31e",
gameSystem: "5e",
campaignId: "561b2041524",
mptype: "mpImage"
},
{
  type:"ruleset",
  

},

]
export {json}